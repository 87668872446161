/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import BannerComponent from './components/banner.component'
import NavbarComponent from './components/navbar.component'

document.addEventListener('DOMContentLoaded', () => {
    new NavbarComponent()

    //Front page only
    if (!IRISCollectionCustomer.isEcoModeActive) {
        new BannerComponent()

        if ($('.home').length > 0) {
            import(
                '@scripts/services/home.service' /* webpackChunkName: "scripts/home.service" */
            ).then(({ default: HomeService }) => {
                new HomeService()
            })
        }
    }

    //Fullscreen template only
    if ($('.fullscreen').length > 0) {
        import(
            '@scripts/services/layout.service' /* webpackChunkName: "scripts/layout.service" */
        ).then(({ default: LayoutService }) => {
            new LayoutService()
        })
    }
})

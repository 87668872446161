import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class BannerComponent {
    constructor() {
        BannerComponent.init()
    }

    static async init() {
        const videoContainer = '[data-banner-video]'
        const swiperContainer = '[data-banner-slider]'

        // Show banner video only on desktop
        if ($(videoContainer).length > 0 && window.matchMedia('(min-width: 1001px)').matches) {
            const videoElmt = document.createElement('video')
            const videoSrc = document.createElement('source')

            // config
            videoSrc.type = $(videoContainer).data('banner-video-type')
            videoSrc.src = $(videoContainer).data('banner-video-src')
            videoElmt.muted = true
            videoElmt.loop = true

            videoElmt.appendChild(videoSrc)
            $(videoContainer).append(videoElmt)
            videoElmt.play()
        } else if ($(swiperContainer).length > 0) {
            const options = {
                slidesPerView: 'auto',
                speed: 1000,
                autoplay: {
                    delay: 3000,
                },
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                pagination: {
                    el: '#swiper-banner-pagination',
                    type: 'bullets',
                    clickable: true,
                },
                navigation: {
                    nextEl: '#swiper-banner-next',
                    prevEl: '#swiper-banner-prev',
                },
                mousewheel: {
                    forceToAxis: true,
                },
                grabCursor: true,
                loop: true,
            }

            // insert navigation / pagination
            $(`${swiperContainer}> *`)
                .wrapAll('<div class="swiper-wrapper"></div>')
                .addClass('swiper-slide')
            $(swiperContainer).append(
                '<div id="swiper-banner-pagination" class="customer-slider-pagination"></div>',
            )
            $('body').addClass('has-banner-slider')

            await Swiper.create(swiperContainer, options)
        }
    }
}

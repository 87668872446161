import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.menu()
        NavbarComponent.gtranslate()
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const offset = 100 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                full = true
            }

            lastScrollTop = scrollTop

            if (scrollTop > offset) {
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
            } else if (scrollTop < offsetOnScroll) {
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
            }
        })
    }

    static menu() {
        const body = $('body')
        const trigger = '[data-menu-trigger]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const toggle = '[data-trigger="wpiris-menu-toggle"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        let isMenuOpen = false

        if (window.matchMedia('(max-width: 1430px)').matches) {
            closeSubmenu()
        }

        //Open/Close menu
        $(trigger).on('click', function (e) {
            e.preventDefault()
            const id = $(this).data('menu-trigger')
            const isActive = $(`[data-menu-trigger="${id}"]`).hasClass('is-active')

            if (!isActive) {
                closeSubmenu()
                $(`[data-menu-trigger="${id}"]`).addClass('is-active')
                $(`[data-menu-id="${id}"]`).addClass('is-active')
            }

            body.addClass('menu-open')
        })

        $(prev).on('click', () => {
            closeSubmenu()
        })

        //Open/Close submenu
        $(more).on('click', function () {
            closeSubmenu()

            const id = $(this).closest('[data-menu-trigger]').data('menu-trigger')

            $(`[data-menu-trigger="${id}"]`).addClass('is-active')
            $(`[data-menu-id="${id}"]`).addClass('is-active')
        })

        $(toggle).on('click', (e) => {
            e.preventDefault()
            if (isMenuOpen) {
                closeSubmenu()
            }
            isMenuOpen = !isMenuOpen
            body.toggleClass('menu-open')
        })

        function closeSubmenu() {
            $('[data-menu-id], [data-menu-trigger]').removeClass('is-active')
        }
    }

    static gtranslate() {
        $(window).on('load', () => {
            setTimeout(() => {
                const current_lang = $('html').attr('lang').replace(/-.*/gi, '')
                const current_list_item = `[data-gt-lang=${current_lang}]`

                if (current_lang !== $(NavbarEnum.LANG_CURRENT).text()) {
                    $(NavbarEnum.LANG_CURRENT).text(current_lang).text()
                }

                if ($(current_list_item).attr('href') === '#') {
                    $(current_list_item).hide()
                }
            }, 250)
        })

        $(NavbarEnum.LANG_ITEM).on('click', function () {
            if ($(this).attr('href') === '#') {
                // Free version
                $(NavbarEnum.LANG_ITEM).show()
                $(this).hide()
                $(NavbarEnum.LANG_CURRENT).text($(this).text())
            }
        })
    }
}
